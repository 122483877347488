//'name' => ['required', 'string'],
            //'image' => ['required'],
           // 'description' => ['required', 'string'],
            // 'phone_number' => ['required', 'string'],
            // 'telephone' => ['required', 'string'],
            // 'address' => ['required', 'string'],
            //'longitude' => ['required', 'string'],
            //'latitude' => ['required', 'string'],
            //'tourist_facility_type_id' => ['required', 'exists:tourist_facility_types'],

// tourist-facilities


// select/tourist-facility-types


export default{
        data() {
            return {
              markerPos: {
                lng: null,
                lat: null,
              },
              fields: [
                { key: "name", label: "اسم" },
                { key: "tourist_facility_type_id", label: "نوع المكان" } ,
                { key: "phone_number", label: "رقم التواصل" } ,
                {key:"telephone",label:"الهاتف"},
                { key :"address", label:"العنوان"},

                { key: "actions", label: "" }
              ],
              estatesFormSchema: {
                name: {
                  component: "FormInput",
                  key: "name",
                  attrs: {
                    id: "name",
                    label: "اسم",
                    rules: "required",
                  },
                },

                tourist_facility_type_id: { 
                  component: "FormSelect",
                  key: "tourist_facility_type_id",
                  attrs: {
                    reduce: (x) => x.id,
                    getOptionLabel: (x) => x.name,
                    id: "tourist_facility_type_id",
                    label: "النوع",
                    ep: "select/tourist-facility-types",
                    rules: "required",
                  },
                },

                phone_number: {
                  component: "FormMask",
                  key: "phone_number",
                  attrs: {
                    options: "phone",
                    // rules: "required",
                    label: "رقم تواصل",
                    dir: "ltr",
                  },
                },
                telephone: {
                  component: "FormMask",
                  key: "telephone",
                  attrs: {  
                    options: "phone",
                    // rules: "required",
                    label: "الهاتف",
                    dir: "ltr",
                  },
                },
      
     
                address: {
                  component: "FormInput",
                  key: "address",
                  attrs: {
                    id: "address",
                    label: "العنوان",
                    rules: "required",
                  },
                },
                description: {
                  component: "FormTextarea",
                  key: "description",
                  attrs: {
                    label: "التفاصيل",
                    rules: "required",
                  },
                },
                long: {
                  key: "long",
                },
                image: {
                  key: "image",
                },
              }
            };
          },
    
}